<template>
<div >
  <div class="products-carousel">
    <div class="main-wrapper" ref="scrollRef">
      <div class="main-wrapper-item" v-for="item in list" :key="item.title" :style="`background-image:url(${item.mainUrl})`">
        <div class="main-wrapper-item-left">
          <div class="title-wrapper">
            <!-- <img :src="item.navUrl" /> -->
            <div class="title-wrapper-title">{{item.title}}</div>
          </div>
          <div class="desc-wrapper">{{item.cont}}</div>
        </div>
      </div>
    </div>
    <div class="slide-wrapper" ref="logoRef">
      <div class="slide-item" v-for="(item, index) in list" :key="item.title">
        <img :src="item.navUrl" @click="handleClick(index)"  />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { nextTick, reactive, ref, toRefs, onMounted, onUnmounted } from 'vue'
const sctollEffect = () => {
  const scrollRef = ref(null)
  const logoRef = ref(null)
  let curIndex = 0
  let aniTime = 0
  const duration = 1000
  const clickFun = (index) => {
    curIndex = index
    moveClick()
  }
  // 初始化
  nextTick(() => {
    if (curIndex === 0) {
      logoRef.value.children[0].style.transform = 'scale(2.5)'
    }
  })

  // 监听鼠标滚动事件
  function scrollFun (ev) {
    const e = ev || window.event
    e.stopPropagation()
    // 如果动画还没执行完，则return
    if (new Date().getTime() < aniTime + duration) {
      return false
    }
    const len = scrollRef.value.children.length
    let direction = 0
    // IE/Opera/Chrome
    if (e.wheelDelta) {
      direction = e.wheelDelta
      if (direction > 0 && curIndex > 0) {
        // 上滚动
        movePrev()
      } else if (direction < 0 && curIndex < len - 1) {
        // 下滚动
        moveNext()
      }
    } else if (e.detail) { // Firefox
      direction = e.detail
      if (direction < 0 && curIndex > 0) {
        // 上滚动
        movePrev()
      } else if (direction > 0 && curIndex < len - 1) {
        // 下滚动
        moveNext()
      }
    }
  }
  function movePrev () {
    aniTime = new Date().getTime()
    scrollRef.value.style.transform = `translateY(${-(--curIndex * 100)}%)`
    imgScale()
  }

  function moveNext () {
    aniTime = new Date().getTime()
    scrollRef.value.style.transform = `translateY(${-(++curIndex * 100)}%)`
    imgScale()
  }

  function moveClick () {
    aniTime = new Date().getTime()
    scrollRef.value.style.transform = `translateY(${-(curIndex * 100)}%)`
    imgScale()
  }
  function imgScale () {
    const len = logoRef.value.children.length
    for (let i = 0; i < len; i++) {
      logoRef.value.children[i].style.transform = 'scale(1.8)'
    }
    logoRef.value.children[curIndex].style.transform = 'scale(2.5)'
  }

  onMounted(() => {
    window.addEventListener('wheel', scrollFun, false)
  })

  onUnmounted(() => {
    window.removeEventListener('wheel', scrollFun, false)
  })

  return { scrollRef, logoRef, clickFun }
}

export default {
  setup () {
    const { scrollRef, logoRef, clickFun } = sctollEffect()
    const handleClick = (index) => {
      clickFun(index)
    }
    const target = reactive({
      list: [
        {
          mainUrl: require('../../assets/img/prj1.png'),
          title: '盾构大脑',
          cont: '盾构大脑立足于盾构生产管理业务，拓展技术管理、设备管理、物资管理、人员管理、安全质量管理、成本管理、安全文明施工管理及信息化业',
          navUrl: require('../../assets/img/nav1.png')
        },
        {
          mainUrl: require('../../assets/img/prj2.png'),
          title: '设备管理',
          cont: '利用信息化、平台化的管理手段通过监控外租设备的使用时间、加班作业、燃油消耗等方面，在提高外租设备的利用率、控制外租成本等关键环',
          navUrl: require('../../assets/img/nav2.png')
        },
        {
          mainUrl: require('../../assets/img/prj3.png'),
          title: '物资管理',
          cont: '物资管理，包括物资计划制订、物资采购、物资使用和物资储备等几个重要环节的精确管理，是企业节约成本的利器。',
          navUrl: require('../../assets/img/nav4.png')
        },
        {
          mainUrl: require('../../assets/img/prj4.png'),
          title: '山东路桥',
          cont: '本项目是“路桥股份”基于信息化现状以及发展战略目标，以“系统整合、整体管控和辅助决策”为核心构建的数字化转型架构，实现业务数字',
          navUrl: require('../../assets/img/nav7.png')
        },
        {
          mainUrl: require('../../assets/img/prj5.png'),
          title: 'PPP绩效考核',
          cont: '全生命周期绩效管理平台是借助“互联网+”思维，来完成项目信息集成与共享，实现了：考核管理流程规范化；信息记录载体一体',
          navUrl: require('../../assets/img/nav3.png')
        },
        {
          mainUrl: require('../../assets/img/prj6.png'),
          title: '智慧工地',
          cont: '智慧工地，是将智慧城市理念在建筑工地进行应用，通过智能化技术、物联网技术等手段围绕施工管理过程，建立互联协同、智能生产、科学管理的施工项目信息化生态圈，并将其在虚拟现实环境下与物联网采集到的工程信息进行数据挖掘分析，实现工程施工可视化智能管理，从而实现智慧建造、绿色建造和生态建造。',
          navUrl: require('../../assets/img/nav5.png')
        },
        {
          mainUrl: require('../../assets/img/prj7.png'),
          title: '盘顶山',
          cont: '济潍高速盘顶山隧道工程建设内容包括∶人员实名制管理、隧道内人员定位、AI行为识别分析、应急电话、广播系统、视频监控、隧道外扬尘',
          navUrl: require('../../assets/img/nav6.png')
        },
        {
          mainUrl: require('../../assets/img/prj8.png'),
          title: '称重',
          cont: '为进一步规范现场物资的称重管理过程、降低使用成本，特开发本系统。本系统除了能有效的管理现场物资的称重过程之外，还与本公司的物资管理系统进行无缝衔接，业务数据互通互联，可以完善物资信息化管理蓝图，达到物资管理目标。',
          navUrl: require('../../assets/img/nav8.png')
        }
      ]
    })

    return { scrollRef, logoRef, ...toRefs(target), handleClick }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variable.scss';
.products-carousel {
  position: relative;
  width: 100%;
  height: calc(100vh - 1rem);
  min-width: 10.24rem;
  min-height: 6rem;
}

.main-wrapper {
  height: 100%;
  width: 100%;
  transition: all .3s;
  &-item {
    @include flex-center( flex-start, center);
    height: 100%;
    width: 100%;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    &-left {
      margin-top: -.3rem;
      width: 3.1rem;
      margin-left: 6.6%;
    }
  }
}

.title-wrapper {
  &-title {
    margin-top: .32rem;
    line-height: .32rem;
    font-size: .28rem;
    font-family: "Gilroy-Bold",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
    font-weight: 700;
  }
}

.desc-wrapper {
  margin-top: .24rem;
  font-size: .16rem;
  line-height: 1.8;
}

.slide-wrapper {
  @include flex-center( space-around, center, nowrap, column);
  position: absolute;
  right: 6.6%;
  top: 2%;
  height: 96%;
  .slide-item {
    width: 4vh;
    height: 4vh;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: all .2s ease-in-out;
    transform: scale(1.8);
    > img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
