<template>
  <div class="party-warpper">暂无信息</div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variable.scss';
.party-warpper {
  position: relative;
  width: 100%;
  height: calc(100vh - 1rem - 2.64rem);
  min-width: 10.24rem;
  min-height: 6rem;
}
</style>
